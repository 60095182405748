













import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
  },
})
export default class AssistMaterialOperation extends Mixins(ClassModeChangeAble) {
  private get classMode() {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private mounted() {
    // cookieのクラスモードが補助教材以外の場合は補助教材モードへ更新
    if (this.classMode != this.CLASS_MODE.ASSIST) this.changeClassMode(this.CLASS_MODE.ASSIST)
  }
}
